import { FC, useEffect, useRef } from 'react'
import { Controller, RegisterOptions, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ClickAwayListener, TextField } from '@mui/material'
import clsx from 'clsx'

// Styles
import styles from './InputEditField.module.scss'

// UI
import { FormHelperText } from '../form-helper-text/FormHelperText'

type InputEditFieldProps = {
  customError?: { type: string; text: string }
  placeholder: string
  preventDefault?: boolean
  rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>
  value: string
  onClose: () => void
  onSubmit: (value: string) => void
}

export const InputEditField: FC<InputEditFieldProps> = (props) => {
  const { customError, placeholder, preventDefault, rules, value, onClose, onSubmit } = props

  const { t } = useTranslation()

  // Refs
  const inputRef = useRef<HTMLInputElement>(null)
  const textFieldRef = useRef<HTMLInputElement>(null)

  const { control, errors, handleSubmit, setError } = useForm({
    mode: 'onChange',
    defaultValues: {
      value,
    },
  })

  // Set error text by rules and translations
  let errorText = ''
  if (errors.value) {
    switch (errors.value.type) {
      case customError?.type:
        errorText = customError?.text ?? ''
        break
      case 'maxLength':
        errorText = t('UI.INPUT_EDIT_FIELD.ERRORS.MAX_LENGTH')
        break
      default:
        errorText = t('UI.INPUT_EDIT_FIELD.ERRORS.REQUIRED')
        break
    }
  }

  useEffect(() => {
    customError && setError('value', { type: customError.type })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customError])

  return (
    <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={onClose}>
      <form aria-label="form" onSubmit={handleSubmit((data) => onSubmit(data.value))}>
        <Controller
          control={control}
          defaultValue={value}
          rules={rules}
          name="value"
          render={({ onChange, onBlur }) => (
            <>
              <TextField
                id="value"
                autoFocus={true}
                className={clsx(styles.textFieldInput, errors.value && styles.textFieldInputError)}
                defaultValue={value}
                inputProps={{
                  ref: textFieldRef,
                  id: 'input-edit-field',
                }}
                inputRef={inputRef}
                placeholder={placeholder}
                onBlur={onBlur}
                onChange={onChange}
                onClick={(event) => {
                  if (preventDefault) {
                    event.preventDefault()
                    event.stopPropagation()
                  }
                }}
              />
              {errors.value && <FormHelperText errorText={errorText} />}
            </>
          )}
        />
      </form>
    </ClickAwayListener>
  )
}
